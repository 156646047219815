import { memo, useCallback, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconCircleClose from "app/pages/.shared/IconCircleClose";
import "./AsideMenuAccountFlashSale.scss";
import SmartDPMenuUnauthenticatedContainer from "app/pages/.shared/SmartDPMenuUnauthenticated/SmartDPMenuUnauthenticatedContainer";
import AppGlobalsContext from "app/AppGlobalsContext";
import { BRANDS } from "app/constants";
import SmartDPMenuAuthenticatedContainer from "app/pages/.shared/SmartDPMenuAuthenticated/SmartDPMenuAuthenticatedContainer";

const AsideMenuAccountFlashSale = ({
	onItemClick = () => {},
	userIsConnected,
	isWhiteLabel,
	onClose,
	fallbackRedirectUrl = "/",
}) => {
	const handleClose = useCallback(() => {
		// onClose permet de surcharger onItemClick
		if (typeof onClose === "function") {
			onClose();
		} else {
			onItemClick();
		}
	}, []);

	const { brand } = useContext(AppGlobalsContext);

	const isBrandEH = brand === BRANDS.EH || brand === BRANDS.EK;

	return (
		<div className="aside-menu-account-flashsale">
			<header className="aside-menu-account-flashsale__header">
				<RelativeLink
					to={{ pathname: fallbackRedirectUrl }}
					className={classNames({
						"aside-menu-account-flashsale__logo": true,
						"aside-menu-account-flashsale__logo-small": isBrandEH,
					})}
					onClick={handleClose}
				>
					<LogoBrandContainer />
				</RelativeLink>
				<div className="aside-menu-account-flashsale__close" onClick={handleClose}>
					<IconCircleClose height={25} width={25} />
				</div>
			</header>
			{isWhiteLabel && !userIsConnected ? (
				<SmartDPMenuUnauthenticatedContainer onItemClick={handleClose} />
			) : (
				<SmartDPMenuAuthenticatedContainer onItemClick={handleClose} />
			)}
		</div>
	);
};

AsideMenuAccountFlashSale.propTypes = {
	onItemClick: PropTypes.func,
	userIsConnected: PropTypes.bool,
	isWhiteLabel: PropTypes.bool,
	onClose: PropTypes.func,
	fallbackRedirectUrl: PropTypes.string,
};

export default memo(AsideMenuAccountFlashSale);
