import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "app/pages/Auth/AuthActions";
import AsideMenu from "@brand/AsideMenu/AsideMenu";
import { isWhiteLabel } from "app/reducers/brandSelector";
import { hideAsideMenu } from "app/pages/Header/menuActionCreators";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	hasAnyCreditNotes,
} from "app/pages/Account/MyCoupons/couponSelector";
import {
	hideCreditNoteNotification,
	hideCreditNotification,
} from "app/pages/Account/MyCoupons/couponActionCreators";
import { getOpenCustomerRequestsCount } from "app/pages/Account/MyBookings/myBookingsSelector";
import { getFallbackRedirectUrl } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	return {
		userIsConnected: isAuthenticated(state),
		partnercode: state.partner.code,
		strategy: state.partner.strategy,
		enableCredits: state.partner.enableCredits,
		enableSponsorship: state.partner.enableSponsorship,
		legalesDocumentName: state.documents.legales,
		isWhiteLabel: isWhiteLabel(state),
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
		showCreditsNotification: state.coupons.showCreditsNotification,
		showCreditNotesNotification: state.coupons.showCreditNotesNotification,
		shouldDisplayCreditNotesEntry: hasAnyCreditNotes(state),
		showCustomerRequestNotification: getOpenCustomerRequestsCount(state) > 0,
		fallbackRedirectUrl: getFallbackRedirectUrl(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ logout, onItemClick: hideAsideMenu, hideCreditNoteNotification, hideCreditNotification },
		dispatch
	);
};

export const AsideMenuAccountContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AsideMenu);
