import { memo } from "react";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import { validatePhoneNumberType } from "app/pages/Account/Profile/profileSchema";

import Button from "app/pages/.shared/form/Button";
import { Form, Formik } from "formik";
import PhoneIntlFormik from "app/pages/.shared/profile/PhoneIntlFormik";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { object, string } from "yup";

import ServiceUnavailable from "./ServiceUnavailable";

import "./ClickToCall.scss";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";

const validationSchema = object().shape({
	phone: string()
		.test("phone (mobile or a fixed-line number)", { id: "error.phone.invalid" }, numberIntl =>
			validatePhoneNumberType(numberIntl)
		)
		.required({ id: "error.phone.required" }),
});

const defaultValues = {
	phone: "",
};

const ClickToCall = ({
	shop,
	initialValues,
	isCallCenterAvailable,
	requestCall,
	handleClose,
	officeHours = [],
}) => {
	return (
		<div className="click-to-call">
			{!isCallCenterAvailable && (
				<div className="click-to-call__unavailable-service">
					<ServiceUnavailable officeHours={officeHours} />
				</div>
			)}
			<Formik
				enableReinitialize
				initialStatus={{ success: undefined }}
				validationSchema={validationSchema}
				initialValues={{ ...defaultValues, ...initialValues }}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={requestCall}
			>
				{({
					errors = {},
					status = {},
					isSubmitting,
					setFieldValue,
					setFieldTouched,
					setFieldError,
					setStatus,
				}) => {
					const hasTechnicalError = status.success === false;
					const isSuccessfull = status.success === true;

					return (
						<Form
							className={classNames("click-to-call__form", {
								"click-to-call__form--unavailable": !isCallCenterAvailable,
							})}
							onChange={event => {
								// Permet de supprimer l'état en erreur du champ en erreur lorsque le user commence à le  modifier
								// Actuellement pas possible de le faire facilement avec formik
								// https://github.com/formium/formik/issues/2727
								if (!isEmpty(errors)) {
									setFieldError(event.target.name);
								}

								// reset status to avoid success message to be dispayed with errors messages after a first successful submit
								setStatus({});
							}}
						>
							<div className="click-to-call__instructions">
								<FormattedMessage id="click.to.call.form.instructions" />
							</div>

							<FormErrorMessages errors={errors} isSubmitting={isSubmitting} />

							<div className="click-to-call__phone">
								<PhoneIntlFormik
									id="phone"
									name="phone"
									shop={shop}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									label={<FormattedMessage id="phone.label" />}
									data-testid="phone-input"
								/>
							</div>

							{errors._error && (
								<AlertMessage
									alertType={ALERT_TYPE.ERROR}
									message={<FormattedMessage id={errors._error.id} />}
								/>
							)}

							{!hasTechnicalError && !isSuccessfull && (
								<Button
									variant="primary"
									submit
									className="click-to-call__button"
									loading={isSubmitting}
									data-testid="create-call-button"
								>
									<FormattedMessage id="click.to.call.button.label" />
								</Button>
							)}
							{hasTechnicalError && (
								<Button
									variant="primary"
									onClick={handleClose}
									className="click-to-call__close-button"
									data-testid="create-call-close-button"
								>
									<FormattedMessage id="general.close.button.label" />
								</Button>
							)}

							{!isSubmitting && status.success && (
								<AlertMessage
									alertType={ALERT_TYPE.SUCCESS}
									message={
										<FormattedMessage id="click.to.call.service.success.label" />
									}
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

ClickToCall.propTypes = {
	shop: PropTypes.string,
	initialValues: PropTypes.object,
	requestCall: PropTypes.func,
	isCallCenterAvailable: PropTypes.bool,
	handleClose: PropTypes.func,
	officeHours: PropTypes.array,
};

export default memo(ClickToCall);
