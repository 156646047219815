import { connect } from "react-redux";
import PropTypes from "prop-types";
import Hamburger from "app/pages/Header/Hamburger/Hamburger";
import IconAccount from "app/pages/.shared/IconAccount/IconAccount";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import "./HamburgerAccount.scss";

const HamburgerAccount = ({ isActive, onToogle, userIsConnected = false }) => {
	return (
		<div className="hamburger-account" data-testid="header-account-link" onClick={onToogle}>
			<Hamburger isActive={isActive} />
			<IconAccount userIsConnected={userIsConnected} />
		</div>
	);
};

HamburgerAccount.propTypes = {
	userIsConnected: PropTypes.bool,
	isActive: PropTypes.bool,
	onToogle: PropTypes.func,
};

const mapStateToProps = state => ({
	userIsConnected: isAuthenticated(state),
});

export default connect(mapStateToProps)(HamburgerAccount);
