function IconHorizontalePlane(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.321 29.173" {...props}>
			<path
				fill="currentColor"
				d="M26.673 9.66c-1.339 1.083-3.347 2.511-5.628 4.054l.693 9.756-1.978 1.383-3.374-8.079c-2.044 1.31-4.083 2.583-5.882 3.67l.018.026.189 2.936-1.593 1.117L4.481 17.9l1.594-1.115 2.695 1.182.018.026c1.636-1.319 3.53-2.8 5.46-4.273L7.81 7.786 9.787 6.4l8.93 3.987c2.241-1.646 4.273-3.037 5.746-3.93 1.116-.674 2.869-.945 3.537-.1.558.907-.3 2.46-1.313 3.279"
				transform="rotate(35.02 16.931 13.102)"
			/>
		</svg>
	);
}

export default IconHorizontalePlane;
