import { createSelector } from "reselect";
import { DEPOSIT_ALLOWED_COUNTRIES } from "app/constants";

const getShop = state => state.shop;

export const isDepositEnabled = createSelector(
	[getShop],
	shop => {
		const [, country] = shop.split("-");
		return DEPOSIT_ALLOWED_COUNTRIES.includes(country);
	}
);
