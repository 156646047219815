import PropTypes from "prop-types";
import IconABTACertification from "./IconABTACertification";
import IconLogoAtol from "app/pages/.shared/static/icons/IconLogoAtol";

import "./BookWithConfidence.scss";

const BookWithConfidence = ({ shop }) => {
	const [, countryCode] = shop.split("-"); // en-GB

	return countryCode !== "GB" ? null : (
		<div className="book-with-confidence">
			<div className="book-with-confidence__container">
				<div className="book-with-confidence__block">
					<p className="book-with-confidence__title">Book with Confidence</p>
					<p className="book-with-confidence__paragraphs">
						All the holidays offers displayed on this website are financially protected
						by the ABTA and ATOL schemes. <br />
						They are sold by ATOL (#11475) and ABTA (#Y6608) holder Perfectstay Travel
						Ltd.
					</p>
				</div>
				<div className="book-with-confidence__inner-wrapper">
					<div className="book-with-confidence__logo-container">
						<div className="book-with-confidence__logo">
							<IconLogoAtol width={60} height={60} />
						</div>
						<div className="book-with-confidence__logo">
							<IconABTACertification width={90} height={90} />
						</div>
					</div>
					<div className="book-with-confidence__two-blocks">
						<div className="book-with-confidence__block">
							<p className="book-with-confidence__block-title">ATOL number 11475</p>
							<p className="book-with-confidence__block-content">
								All the flight-inclusive holidays on this website are financially
								protected by the ATOL scheme. When you pay you will be supplied with
								an ATOL Certificate. Please ask for it and check to ensure that
								everything you booked (flights, hotels and other services) is listed
								on it. The flight-inclusive holidays displayed on this website are
								sold by ATOL (#11475) holder Perfectstay Travel Ltd. Please see our
								booking conditions for further information or for more information
								about financial protection and the ATOL Certificate go to:{" "}
								<a
									rel="noopener noreferrer"
									target="_blank"
									href="http://www.atol.org.uk/ATOLCertificate"
								>
									http://www.atol.org.uk/ATOLCertificate
								</a>
							</p>
						</div>
						<div className="book-with-confidence__block">
							<p className="book-with-confidence__block-title">ABTA number Y6608</p>
							<p className="book-with-confidence__block-content">
								As a member of ABTA (#Y6608), you can be assured that you are
								receiving the highest level of service and are offered excellent
								choice and value. If your holiday is not inclusive of flights then
								ABTA will financially protect your holiday similar to ATOL, unless
								you are already abroad in which case you be returned to the point
								where your contracted arrangements with us commenced. Find out more:{" "}
								<a
									rel="noopener noreferrer"
									target="_blank"
									href="http://www.abta.com"
								>
									http://www.abta.com
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

BookWithConfidence.propTypes = {
	shop: PropTypes.string,
};

export default BookWithConfidence;
