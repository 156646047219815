const IconCircleClose = props => (
	<svg viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M24.76 10.07a12.5 12.5 0 0 0-17-9.11 12.5 12.5 0 0 0 4.78 24 12.5 12.5 0 0 0 12.22-14.89Zm-6.55 6.73a1 1 0 0 1-1.42 1.41l-4.29-4.29-4.29 4.29a1 1 0 0 1-1.42-1.41l4.3-4.29-4.3-4.3A1 1 0 1 1 8.21 6.8l4.29 4.33 4.29-4.29a.999.999 0 0 1 1.42 0 1 1 0 0 1 0 1.41l-4.3 4.3 4.3 4.25Z"
			fill="currentColor"
		/>
	</svg>
);

export default IconCircleClose;
