import "./PhoneNumberSva.scss";
import { sendTagOnContactCall } from "app/utils/analytics";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

/*
 **
 ** 					USE CASES :
 ** https://www.infosva.org/index.php#signaletique
 **
 */

const PhoneNumberSVA = ({ dialPhone, displayPhone, callPrice, svaVariant }) => {
	const retrievePricePerMinute = () => {
		const priceRegex = RegExp(/(\d+(?:[,.]\d+)?€)/);
		const matchedArray = callPrice.match(priceRegex);

		return matchedArray && matchedArray.length ? matchedArray[0] : "";
	};

	const pricePerMinute = !svaVariant.freeFirstLine && retrievePricePerMinute();

	return (
		<div className="phone-number-sva">
			<div className="phone-number-sva__number">
				<a
					className={"phone-number-sva__link"}
					onClick={sendTagOnContactCall}
					href={`tel:${dialPhone}`}
				>
					<div
						className="phone-number-sva__number-content"
						style={{ color: svaVariant.textColor }}
					>
						{displayPhone.join(" ")}
					</div>
				</a>
			</div>
			<div
				className="phone-number-sva__pricing"
				style={{ backgroundColor: svaVariant.bgColor }}
			>
				<div className="phone-number-sva__pricing-content">
					<span className="phone-number-sva__entire-line">
						{svaVariant.freeFirstLine ? (
							<span>
								<FormattedMessage id={svaVariant.freeFirstLine} />
								<br />
							</span>
						) : (
							<FormattedMessage
								id={"help.desk.service.not.free"}
								values={{ pricePerMinute }}
							/>
						)}
						{!svaVariant.freeFirstLine && (
							<span className="phone-number-sva__pricing-time">
								<FormattedMessage id={"help.desk.service.not.free.timing"} />
								<br />
							</span>
						)}
						<FormattedMessage id={svaVariant.secondLine} />
					</span>
				</div>
			</div>
		</div>
	);
};

PhoneNumberSVA.propTypes = {
	dialPhone: PropTypes.string.isRequired,
	displayPhone: PropTypes.arrayOf(PropTypes.string).isRequired,
	callPrice: PropTypes.string,
	svaVariant: PropTypes.object,
};

export default PhoneNumberSVA;
