import { Fragment, memo } from "react";
import PropTypes from "prop-types";
import "./AsidePanel.scss";
import classNames from "classnames";

const AsidePanel = ({ children, isOpen }) => {
	const asideClass = classNames({
		"aside-panel": true,
		"aside-panel--pushed": isOpen,
	});
	const overlay = classNames("aside-panel-overlay", { "aside-panel-overlay--visible": isOpen });
	return (
		<Fragment>
			<div className={asideClass}>{children}</div>
			<div className={overlay} />
		</Fragment>
	);
};

AsidePanel.propTypes = {
	isOpen: PropTypes.bool,
};

export default memo(AsidePanel);
